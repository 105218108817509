.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Utils */
@media (max-width: 1125px) {
  .flex-center {
    display: flex;
    justify-content: center;
  }
}

/* Image Background */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  height: inherit;
  width: inherit;
  background-color: #691fca;
  overflow-x: hidden;
  overflow-y: hidden;
}

.background {
  height: inherit;
  width: inherit;
  position: fixed;
  display: flex;
}

@media (max-width: 1125px) {
  .container {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .background {
    height: 100%;
  }
}

/* Logo */

.logo {
  position: relative;
  left: 50px;
  width: 50px;
  top: 50px;
}

@media (max-width: 1125px) {
  .logo {
    position: relative;
    left: 0px;
    width: 50px;
    top: 50px;
  }
}

/* Infos */

.infos {
  position: relative;
  top: 7em;
  left: 15em;
  color: white;
}
.infos h1 {
  color: #00feff;
  font-family: "DanzzaBold";
  font-size: 80px;
  margin-top: 0;
  margin-bottom: 0;
}
.infos h3 {
  color: #00feff;
  font-family: "DanzzaBold";
  font-size: 35px;
  margin-bottom: 0;
}

@media (max-width: 1125px) {
  .infos {
    position: relative;
    top: 30em;
    left: 0em;
    color: white;
    text-align: center;
  }
  .infos h1 {
    color: #00feff;
    font-family: "DanzzaBold";
    font-size: 50px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .infos h3 {
    color: #00feff;
    font-family: "DanzzaBold";
    font-size: 15px;
    margin-bottom: 0;
  }
}

/* Mock Up */

.mockup {
  position: relative;
  float: right;
  top: -22em;
}

@media (max-width: 1125px) {
  .mockup {
    position: relative;
    float: right;
    top: -13em;
  }
}

/* Newsletter Button */

.wrapper {
  font-family: "DanzzaBold";
  position: absolute;
  top: 25em;
  left: 5em;
  height: 50px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (max-width: 1125px) {
  .wrapper {
    top: 18em;
    left: 6em;
    margin-bottom: 15px;
  }
}
.wrapper .btn-1 {
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 50%;
  background: #00feff;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
  line-height: 50px;
  border-radius: 90px;
  cursor: pointer;
  transform: translateX(-45%);
  transition: 1s ease-in-out;
}
#click:checked ~ .btn-1 {
  width: 150px;
}
#click:checked ~ .field {
  width: 270px;
  opacity: 1;
}
#click:not(:checked) ~ .btn-1 {
  width: 300px;
}
#click:not(:checked) ~ .field {
  width: 270px;
  opacity: 0;
}
.wrapper .field {
  opacity: 0;
  pointer-events: auto;
  transform: translateX(-70%);
  transition: 1s ease-in-out;
  color: white;
}
.wrapper .field input {
  font-family: "DanzzaBold";
  font-size: 20px;
  text-align: center;
  width: 160px;
  height: 44px;
  border: 3px solid #fff;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.548);
  border-radius: 90px;
  padding: 0 130px 0 15px;
  outline: none;
}
::placeholder {
  color: rgb(0, 0, 0);
  opacity: 0.5; /* Firefox */
  font-family: "DanzzaBold";
}
.wrapper #click {
  display: none;
}

/* Copyright */

.copyright {
  position: fixed;
  transform: rotateZ(90deg);
  right: 0px;
  bottom: 100px;
  color: white;
  font-size: 12px;
  font-family: "DanzzaRegular";
}
@media (max-width: 1125px) {
  .copyright {
    display: none;
  }
}
