html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "DanzzaRegular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "DanzzaRegular";
  src: local("DanzzaRegular"),
    url("./fonts/Danzza/Danzza\ Regular.otf") format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "DanzzaMedium";
  src: local("DanzzaMedium"),
    url("./fonts/Danzza/Danzza\ Medium.otf") format("truetype");
  font-weight: medium;
}

@font-face {
  font-family: "DanzzaBold";
  src: local("DanzzaBold"),
    url("./fonts/Danzza/Danzza\ Bold.otf") format("truetype");
  font-weight: bold;
}
